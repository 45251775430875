<template>
  <TabPanel v-bind="$attrs" class="tw-flex tw-h-full tw-flex-col tw-justify-between">
    <ol v-if="sortedSpeakers.length" class="tw-flex tw-flex-wrap tw-gap-2 sm:tw-grid sm:tw-grid-cols-5">
      <li v-for="speaker in sortedSpeakers" :key="speaker.speaker_id" class="tw-w-15 sm:tw-w-auto">
        <Button
          :disabled="locked"
          color="transparentWhite"
          class="tw-w-full tw-p-0"
          @click="moveToSpeaker({ speaker_id: speaker.speaker_id })"
        >
          <Speaker
            :speaker
            class="tw-w-full"
            :deleting="speakerDeleting(speaker.speaker_id)"
            @delete="triggerDeleteSpeaker"
          />
        </Button>
      </li>
    </ol>

    <Text v-else>{{ $t('speakers_presets_tabs.speakers.no_speakers') }}</Text>

    <Text size="large" class="tw-mt-5 tw-text-right md:tw-mt-1.5">
      <Link tag="button" scheme="underline" @click="openSettings">
        {{ $t('settings') }}
      </Link>
    </Text>
  </TabPanel>

  <SpeakersModal v-model="settingsOpen" />
</template>

<script setup>
import api from '@/api';
import useDraftState from '@/composables/draft_state';
import useLoadingRequest from '@/composables/loading_request';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { Button, Link, TabPanel, Text } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';

import Speaker from './Speaker.vue';
import SpeakersModal from './SpeakersModal.vue';

const appStore = useAppStore();
const { locked, activeSpeakersTimeWindow: activeSpeakersTimeWindowStore } = storeToRefs(appStore);
const dataStore = useDataStore();
const { speakers } = storeToRefs(dataStore);

const settingsOpen = ref(false);
const deletedIds = ref([]);

const notDeletedSpeakers = computed(() =>
  speakers.value.filter(({ speaker_id: speakerId }) => !deletedIds.value.includes(speakerId)),
);
const activeSpeakers = computed(() =>
  notDeletedSpeakers.value.filter((speaker) => speaker.detection_age_seconds <= activeSpeakersTimeWindowStore.value),
);
const sortedSpeakers = computed(() => activeSpeakers.value.slice().sort((a, b) => a.x_pos - b.x_pos));

const { trigger: moveToSpeaker } = useLoadingRequest(api.moveToSpeaker);

const { loading: deleting, trigger: deleteSpeaker } = useDraftState(api.deleteSpeaker, {
  withoutData: true,
  statePath: ['speakers'],
  draftStateValue: notDeletedSpeakers,
  doNotAbortWhenLoading: true,
});

const triggerDeleteSpeaker = async (speakerId) => {
  if (locked.value) return;

  deletedIds.value.push(speakerId);

  await deleteSpeaker({ key: 'speaker_id', speakerId });
};

const speakerDeleting = (speakerId) => deleting.value && deletedIds.value.some((id) => id === speakerId);

const openSettings = () => {
  settingsOpen.value = true;
};

watch(deleting, (newDeleting) => {
  if (newDeleting) return;

  deletedIds.value = [];
});
</script>
